import React, { Component, createRef } from "react";
import { withAlert } from "react-alert"; // HOC to show the alert message
import Config, { entryMode } from "../../../data/siteconfig";
import sHelpers from "../../../modules/helpers/statichelpers";
//import AddItemPopup from '../../../component/popup/additem';
import AddItemRatePopup from '../../../component/popup/additemrate';
import "./kitchenclosing.scss";
import "./autocomplete.scss";
import PageLoading from "../../../component/loadinganimation/pageloading";

const addItemRef = React.createRef();
// Page is Created by vijay on 07-10-2024
class kitchenclosingentry extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			paddingTop: 0,
			paddingBottom: 0,
			propertyList: [],
			storeList: [],
			kitchenList: [],
			selectedPropertyCode: 0,
			selectedPropertyName: "",
			selectedStoreCode: 0,
			selectedStoreName: "",
			variationNo: 0,
			kitchenclosingDate: "",
			items: [],
			selectedItems: [],
			fields: {},
			btnDisabled: false,
			btnVisible: true,
			btnDeleteVisabled: false,
			ctrlDisabled: false,
			selectedItemCode: 0,
			selectedItemUnits: "",
			selectedItemStock: 0,
			selectedItemRate: 0,
			filteredSuggestions: [],
			itemInputValue: "",
			selectedItemValue: "",
			mode: "-",
			selectedKitchenclosingCode: 0,
			typeLists: [
				{ name: "Item", id: "1" },
				{ name: "POS / Recipe Item", id: "2" },
				{ name: "Ingredient / Sub Recipe", id: "3" },
			],
			selectedTypeId: "1", // Set the default to "Item"
			selectedTypeName: "Item", // Set the default name to "Item"
			displayKitchenNo: 0,
			showStoreList: true,
			KitchenClosingAmount: "",
			selectedRecipeCode: null,
			editdisabled: false,
			resetdisabled: true,
			showEditRateIcon: false,
		};
		this.mainRef = createRef();
		this.headerRef = createRef();
		this.footerRef = createRef();
		this.itemSearchRef = React.createRef();
		this.qtyRef = React.createRef();
		this.enteredByRef = React.createRef();
		this.handleChangeKitchenList = this.handleChangeKitchenList.bind(this);
	}

	updateStyles = () => {
		const windowHeight = window.innerHeight;
		const headerHeight = this.headerRef.current
			? this.headerRef.current.offsetHeight
			: 0;
		const footerHeight = this.footerRef.current
			? this.footerRef.current.offsetHeight
			: 0;

		if (this.mainRef.current) {
			this.mainRef.current.style.minHeight = `${windowHeight}px`;
			this.setState({
				paddingTop: headerHeight + 10,
				paddingBottom: footerHeight + 10,
			});
		}
	};

	componentDidMount() {
		this.updateStyles();
		window.addEventListener("resize", this.updateStyles);

		if (!sHelpers.LoggedinInputValidation()) {
			this.props.alert.error("Session Expired! Please log in again.");
			this.setState({ loading: true });
			setTimeout(() => {
				this.props.history.push("/login");
			}, Config.minMsgTimeout);
			return false;
		}

		this.setState({
			propertyList: sHelpers.secureStorage.getItem("inv.user.properties"),
		});

		if (sHelpers.secureStorage.getItem("inv.user.properties").length > 0) {
			let kitchenclosingDate = sHelpers.secureStorage.getItem(
				"inv.user.properties"
			)[0].RunDate;
			const _filterStore = sHelpers.secureStorage
				.getItem("inv.user.stores")
				.filter(
					(s) =>
						s.PropertyCode ===
						sHelpers.secureStorage.getItem("inv.user.properties")[0]
							.PropertyCode
				);

			this.setState({
				storeList: _filterStore,
				kitchenclosingDate: kitchenclosingDate,
				selectedPropertyCode: sHelpers.secureStorage.getItem(
					"inv.user.properties"
				)[0].PropertyCode,
				selectedPropertyName: sHelpers.secureStorage.getItem(
					"inv.user.properties"
				)[0].PropertyName,
			});

			if (_filterStore.length > 0) {
				this.setState({
					selectedStoreCode: _filterStore[0].StoreCode,
					selectedStoreName: _filterStore[0].StoreName,
				});
				this.getStoreItems(
					sHelpers.secureStorage.getItem("inv.user.properties")[0].PropertyCode,
					_filterStore[0].StoreCode
				);
			}
		}

		//if first property having kitchenlist
		const propertyList = sHelpers.secureStorage.getItem("inv.user.properties");

		if (propertyList && propertyList.length > 0) {
			this.setState({ propertyList }, () => {
				// Get the first property code after state update
				const firstPropertyCode = this.state.propertyList[0].PropertyCode;

				// Call handleChangeProperty with the first property code
				this.handleChangeProperty({ target: { value: firstPropertyCode } });
			});
		}

		if (this.props.location.state) {
			this.setState({ mode: this.props.location.state.mode });
			this.setState({
				kitchenclosingno: this.props.location.state.kitchenclosingno,
			});
			setTimeout(() => {
				if (
					this.props.location.state.mode === "View" ||
					this.props.location.state.mode === "Delete" ||
					this.props.location.state.mode === "Edit"
				) {
					this.getViewKitchenClosingList(
						this.props.location.state.kitchenclosingno
					);
				}

				this.handleMode();
			}, Config.minMsgTimeout);
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateStyles);
	}


	handleAddItemRatePopup() {
		if (addItemRef.current) {
			addItemRef.current.handleAddItemRatePopup();
		}
	};

	handleRateUpdate = (newRate) => {
		this.setState({
			selectedItemRate: newRate,
		});
	};

	getStoreItems = async (propertyCode, storeCode) => {
		try {
			var requestIP = "0.0.0.1";
			this.setState({ storeItems: [] });
			const encodedCredentials = btoa(
				`${Config.invAPIUserName}:${Config.invAPIPassword}`
			);

			await fetch(
				Config.baseAPIUrl +
				"common/storeIssueditems?propertycode=" +
				propertyCode +
				"&storecode=" +
				storeCode +
				"&requestip=" +
				requestIP,
				{
					method: "GET",
					headers: {
						Authorization: "Basic " + encodedCredentials,
						"Content-type": "application/json",
					},
				}
			)
				.then((res) => res.json())
				.then((response) => {
					//  console.log(response);
					if (response.IsSuccessful) {
						const mappedSuggestions = response.Items.map((item) => ({
							ItemName: item.ItemName,
							ItemCode: item.ItemCode,
							UOM: item.Units,
							Rate: item.ActualRate,
							PickRate: null,
							IngredientCode: null,
						}));
						this.setState({
							items: mappedSuggestions,
							filteredSuggestions: [],
							itemInputValue: "",
							selectedItemCode: 0,
							selectedItemValue: "",
						});
					} else {
						this.setState({ loading: false });
						this.props.alert.error(response.Message);
						sHelpers.secureStorage.setItem("inv.store.items", []);
					}
				})
				.catch((err) => {
					console.log(err);
					// this.setState({ loading: false });
					this.props.alert.error(err.message);
					return;
				});
		} catch (error) {
			console.error("Fetch error:", error);
			// You can set an error state here or handle it as needed
			throw error;
		}
	};

	handleChangeProperty = async (event) => {
		const selectedPropertyCode = event.target.value;

		const _filterStore = sHelpers.secureStorage
			.getItem("inv.user.stores")
			.filter((s) => s.PropertyCode === Number(selectedPropertyCode));

		const _filterProperty = sHelpers.secureStorage
			.getItem("inv.user.properties")
			.filter((p) => p.PropertyCode === Number(selectedPropertyCode));

		this.setState({
			storeList: _filterStore,
			selectedTypeId: "1",
			showStoreList: true,
			kitchenclosingDate:
				_filterProperty.length > 0 ? _filterProperty[0].RunDate : "",
			selectedPropertyCode: selectedPropertyCode,
			selectedPropertyName:
				_filterProperty.length > 0 ? _filterProperty[0].PropertyName : "",
		});

		if (this.props.location.state.mode !== "Edit") {
			this.setState({ selectedItems: [] });
		}

		if (_filterStore.length > 0) {
			this.setState({
				selectedStoreCode: _filterStore[0].StoreCode,
				selectedStoreName: _filterStore[0].StoreName,
			});
			this.getStoreItems(selectedPropertyCode, _filterStore[0].StoreCode);
		}

		await this.getKitchenList(selectedPropertyCode);

		const { kitchenList } = this.state;
		if (kitchenList.length > 0) {
			this.setState({ selectedKitchenclosingCode: kitchenList[0].KitchenCode });
		} else {
			this.setState({ selectedKitchenclosingCode: "" });
		}

		if (this.props.location.state.mode !== "Edit") {
			this.clearData();
		}
	};

	handleReload() {
		window.location.reload(true);
	}

	handleAddItemClick() {
		let getSelectItems = this.state.selectedItems;
		let findItem = [];

		if (parseInt(this.state.selectedTypeId) === 1) {
			findItem = getSelectItems.filter(
				(itm) =>
					itm.ItemCode === this.state.selectedItemCode.toString() && // Corrected capitalization: 'ItemCode'
					itm.StoreCode === parseInt(this.state.selectedStoreCode) &&
					itm.TypeCode === parseInt(this.state.selectedTypeId) // Convert selectedTypeId to integer
			);
		}
		if (parseInt(this.state.selectedTypeId) !== 1) {
			findItem = getSelectItems.filter(
				(itm) =>
					itm.ItemCode === this.state.selectedItemCode.toString() &&
					itm.TypeCode === parseInt(this.state.selectedTypeId)
			);
		}

		const trimmedItemValue = this.state.selectedItemValue.toString().trim();

		if (parseInt(this.state.selectedTypeId) === 1) {
			findItem = getSelectItems.filter(
				(itm) => itm.ItemName === trimmedItemValue
			);
		}
		if (parseInt(this.state.selectedTypeId) !== 1) {
			findItem = getSelectItems.filter(
				(itm) => itm.ItemName === trimmedItemValue
			);
		}

		if (findItem.length) {
			this.props.alert.error("Item already added.");
			document.getElementById("item-name").focus();
			return false;
		}

		const selectedItem = document.getElementById("item-name");
		const qty = this.state.fields["kitchenqty"]
			? parseFloat(this.state.fields["kitchenqty"])
			: 0;
		let fields = this.state.fields;

		if (!sHelpers.validateNumeric(qty)) {
			this.props.alert.error("Please enter the Valid Qty");
			document.getElementById("kitchen-qty").focus();
			fields["kitchenqty"] = "";
			this.setState({ fields });
			this.handleScrollIntoView(this.qtyRef);
			return false;
		}
		if (!this.state.selectedItemValue) {
			this.setState({ itemInputValue: "" });
			this.props.alert.error("Please select the item");
			document.getElementById("item-name").focus();
			fields["kitchenqty"] = "";
			this.setState({ fields });
			this.handleScrollIntoView(this.itemSearchRef);
			return false;
		}

		// console.log('qty',qty);
		if (qty <= 0) {
			this.props.alert.error("Please enter the valid qty");
			fields["kitchenqty"] = "";
			this.setState({ fields });
			window.scrollTo(0, 0);
			document.getElementById("kitchen-qty").focus();
			this.handleScrollIntoView(this.qtyRef);
			return false;
		}

		if (parseFloat(this.state.selectedItemRate) === 0) {
			this.props.alert.error("Rate must be greater than zero.");
			document.getElementById("item-name").focus();
			fields["kitchenqty"] = "";
			this.setState({ fields });
			this.handleScrollIntoView(this.itemSearchRef);
			return false;
		}
		let kitchenClosingAmount =
			parseFloat(qty.toFixed(3)) * parseFloat(this.state.selectedItemRate);

		let obj = {
			StoreCode:
				this.state.selectedTypeId === "1"
					? parseInt(this.state.selectedStoreCode)
					: 0,
			StoreName:
				this.state.selectedTypeId === "1" ? this.state.selectedStoreName : "",
			ItemName: selectedItem.value.trim(),
			ItemCode:
				this.state.selectedTypeId === "1"
					? parseInt(this.state.selectedItemCode)
					: this.state.selectedItemCode,
			TypeCode: this.state.selectedTypeId,
			TypeName: this.state.selectedTypeName,
			units: this.state.selectedItemUnits,
			KitchenClosingRate: parseFloat(this.state.selectedItemRate).toFixed(3),
			KitchenClosingQuantity: parseFloat(qty).toFixed(3),
			KitchenClosingAmount: parseFloat(kitchenClosingAmount).toFixed(3),
			RecipeItemCode: this.state.selectedRecipeCode,
		};
		getSelectItems.push(obj);
		this.setState({
			selectedItems: getSelectItems,
			itemInputValue: "",
			selectedItemValue: "",
			filteredSuggestions: [],
			selectedItemCode: 0,
			selectedItemUnits: "",
			//selectedItemStock: 0,
			selectedItemRate: 0,
			selectedRecipeCode: null,
			showEditRateIcon: false,
		});
		document.getElementById("item-name").focus();
		fields["kitchenqty"] = "";
		this.setState({ fields });
	}

	handleDeleteItemCick(ItemCode, storeCode) {
		let getSelectItems = this.state.selectedItems;
		let index = getSelectItems.findIndex(
			(i) => i.ItemCode === ItemCode && i.storeCode === storeCode
		);
		getSelectItems.splice(index, 1);
		this.setState({ selectedItems: getSelectItems });
		this.props.alert.success("Item deleted successfully!");
		document.getElementById("item-name").focus();
		this.handleScrollIntoView(this.itemSearchRef);
	}

	handleStoreChange(event) {
		const selectedStoreCode = event.target.value; // This is a string
		// Find the selected store by comparing store codes
		const selectedStore = this.state.storeList.find(
			(store) => store.StoreCode === parseInt(selectedStoreCode, 10)
		); // Convert to number

		// Ensure that selectedStore exists to avoid potential errors
		if (selectedStore) {
			this.setState({
				selectedStoreCode: selectedStoreCode, // StoreCode remains a string
				selectedStoreName: selectedStore.StoreName, // Update selectedStoreName state
			});
		}

		this.getStoreItems(this.state.selectedPropertyCode, event.target.value);
	}

	handleChange(field, e) {
		let fields = this.state.fields;
		fields[field] = e.target.value;
		this.setState({ fields });
	}

	handleChangeKitchenList(event) {
		const selectedKitchenCode = event.target.value; // Get the selected value
		this.setState({
			selectedKitchenclosingCode: selectedKitchenCode, // Update state with selected KitchenCode
		});
		//console.log("123", this.state.selectedKitchenclosingCode);
	}

	handleTypeChange = (event) => {
		const selectedValue = event.target.value;
		const selectedType = this.state.typeLists.find(
			(type) => type.id === selectedValue
		);
		const selectedTypeName = selectedType ? selectedType.name : "";
		const showStoreList = selectedValue === "1";

		this.setState(
			{
				selectedTypeId: selectedValue,
				selectedTypeName: selectedTypeName,
				showStoreList: showStoreList,
			},
			() => {
				// Fetch data based on the selected type after state update
				if (selectedValue === "2") {
					this.getPosRecipeItems();
				} else if (selectedValue === "3") {
					this.getIngredientItems();
				} else {
					this.getStoreItems(
						this.state.selectedPropertyCode,
						this.state.selectedStoreCode
					);
				}
			}
		);
	};

	handleItemSelect = (
		value,
		ItemCode,
		units,
		rate,
		pickRate,
		RecipeItemcode,
		ingredientcode
	) => {
		//console.log("pickRate", pickRate);
		if (pickRate === 1) {
			if (this.state.selectedTypeId === "2") {
				this.getPosRecipeItemsRate(RecipeItemcode);
				this.setState({ selectedRecipeCode: RecipeItemcode });
			} else {
				this.setState({ selectedItemRate: rate });
			}
		} else if (this.state.selectedTypeId === "3") {
			this.getIngredientItemRate(ingredientcode);
		} else {
			this.setState({ selectedItemRate: rate });
		}

		this.setState({
			filteredSuggestions: [],
			itemInputValue: value,
			selectedItemValue: value,
			selectedItemCode: ItemCode,
			selectedItemUnits: units,
			//selectedItemStock: cstock,
			showEditRateIcon: true
		});

		let fields = this.state.fields;
		fields["kitchenqty"] = "";
		this.setState({ fields });
		document.getElementById("kitchen-qty").focus();
	};

	handleItemChange = (event) => {
		const inputValue = event.target.value;

		// Filter suggestions based on input value
		const filteredSuggestions = this.state.items.filter((suggestion) =>
			suggestion.ItemName.toLowerCase().includes(inputValue.toLowerCase())
		);

		this.setState({
			itemInputValue: inputValue,
			filteredSuggestions: filteredSuggestions,
		});
		this.setState({
			selectedItemValue: "",
			selectedItemCode: 0,
			selectedItemUnits: "",
			//selectedItemStock: 0,
			selectedItemRate: 0,
			selectedRecipeCode: null,
		});
	};

	handleItemFocus = (event) => {
		event.target.select();
	};

	handleMode() {
		if (entryMode.view === this.state.mode) {
			this.setState({
				btnVisible: false,
				ctrlDisabled: true,
				editdisabled: true,
				resetdisabled: false,
			});
		}

		if (entryMode.edit === this.state.mode) {
			this.setState({
				btnVisible: true,
				ctrlDisabled: false,
				editdisabled: true,
				resetdisabled: false,
			});
		}

		if (entryMode.delete === this.state.mode) {
			this.setState({
				btnDeleteVisabled: true,
				btnVisible: false,
				ctrlDisabled: true,
				editdisabled: true,
				resetdisabled: false,
			});
		}
	}

	clearData() {
		let fields = this.state.fields;
		this.setState({
			selectedItems: [],
			itemInputValue: "",
			selectedItemValue: "",
			filteredSuggestions: [],
			selectedItemCode: 0,
			selectedItemUnits: "",
			//selectedItemStock: 0,
			selectedItemRate: 0,
			selectedRecipeCode: null,
		});
		if (
			!(
				this.props.location.state.mode === "View" ||
				this.props.location.state.mode === "Delete"
			)
		) {
			this.itemSearchRef.current.value = "";
			this.itemSearchRef.current.focus();
		}

		fields["kitchenqty"] = "";
		this.setState({ fields });
	}

	goBack() {
		this.props.history.push("/kitchenclosing");
	}

	handleScrollIntoView(scrollRef) {
		window.scrollTo(0, 0);
		setTimeout(() => {
			scrollRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
		}, Config.averageMsgTimeout);
	}

	// kitchens list while property wise
	getKitchenList = async (propertyCode) => {
		let RequestIP = "127.0.0.0";

		//console.log(propertyCode);
		try {
			const encodedCredentials = btoa(
				`${Config.invAPIUserName}:${Config.invAPIPassword}`
			);

			await fetch(
				Config.baseAPIUrl +
				"common/propertykitchens?" +
				"propertycode=" +
				propertyCode +
				"&requestip=" +
				RequestIP,
				{
					method: "GET",
					headers: {
						Authorization: "Basic " + encodedCredentials,
						"Content-type": "application/json",
					},
				}
			)
				.then((res) => res.json())
				.then((response) => {
					//	console.log("getpropertykitchens", response);
					if (response.IsSuccessful && response.Kitchens.length > 0) {
						this.setState({ kitchenList: response.Kitchens });
					} else {
						// this.props.alert.error(response.Message);
						this.setState({ kitchenList: [] });
					}
				})
				.catch((err) => {
					console.log(err);
					this.props.alert.error(err.message);
					return;
				});
		} catch (error) {
			console.error("Fetch error:", error);
			throw error;
		}
	};

	//POS Recipe Items
	getPosRecipeItems = async () => {
		try {
			let RequestIP = "127.0.0.0";
			var _orgid = sHelpers.secureStorage.getItem("inv.organization.id");
			//console.log('_orgid',_orgid);

			const encodedCredentials = btoa(
				`${Config.invAPIUserName}:${Config.invAPIPassword}`
			);

			await fetch(
				Config.baseAPIUrl +
				"common/posrecipeitems?organizationId=" +
				_orgid +
				"&requestip=" +
				RequestIP,
				{
					method: "GET",
					headers: {
						Authorization: "Basic " + encodedCredentials,
						"Content-type": "application/json",
					},
				}
			)
				.then((res) => res.json())
				.then((response) => {
					//	console.log("POSRecipeItems", response);
					if (response.IsSuccessful) {
						//this.setState({ filteredSuggestions: response.POSRecipeItems });

						this.setState({
							items: response.POSRecipeItems,
							filteredSuggestions: [],
							itemInputValue: "",
							selectedItemCode: 0,
							selectedItemValue: "",
						});

						//this.itemSearchRef.current.value = "";
						//this.itemSearchRef.current.focus();
					} else {
						this.setState({ loading: false });
						this.props.alert.error(response.Message);
					}
				})
				.catch((err) => {
					console.log(err);
					this.props.alert.error(err.message);
					return;
				});
		} catch (error) {
			console.error("Fetch error:", error);
			throw error;
		}
	};

	//POS Recipe Items Rate
	getPosRecipeItemsRate = async (recipecode) => {
		try {
			let RequestIP = "127.0.0.0";
			var _orgid = sHelpers.secureStorage.getItem("inv.organization.id");
			//console.log("_orgid", _orgid);

			const encodedCredentials = btoa(
				`${Config.invAPIUserName}:${Config.invAPIPassword}`
			);

			await fetch(
				Config.baseAPIUrl +
				"common/posrecipeitemrate?organizationId=" +
				_orgid +
				"&requestip=" +
				RequestIP +
				"&recipecode=" +
				recipecode,
				{
					method: "GET",
					headers: {
						Authorization: "Basic " + encodedCredentials,
						"Content-type": "application/json",
					},
				}
			)
				.then((res) => res.json())
				.then((response) => {
					//console.log("posrecipeitemrate", response);
					if (response.IsSuccessful) {
						this.setState({ selectedItemRate: response.Rate });
					} else {
						this.setState({ loading: false });
						this.props.alert.error(response.Message);
					}
				})
				.catch((err) => {
					console.log(err);
					this.props.alert.error(err.message);
					return;
				});
		} catch (error) {
			console.error("Fetch error:", error);
			throw error;
		}
	};

	//Ingredient Item
	getIngredientItems = async () => {
		try {
			let RequestIP = "127.0.0.0";
			var _orgid = sHelpers.secureStorage.getItem("inv.organization.id");
			const encodedCredentials = btoa(
				`${Config.invAPIUserName}:${Config.invAPIPassword}`
			);

			await fetch(
				Config.baseAPIUrl +
				"common/ingredientitems?organizationId=" +
				_orgid +
				"&requestip=" +
				RequestIP,
				{
					method: "GET",
					headers: {
						Authorization: "Basic " + encodedCredentials,
						"Content-type": "application/json",
					},
				}
			)
				.then((res) => res.json())
				.then((response) => {
					//console.log("IngredientItems", response);
					if (response.IsSuccessful) {
						const mappedSuggestions = response.IngredientItems.map((item) => ({
							ItemName: item.IngredientName, // Mapping IngredientName to ItemName
							ItemCode: item.IngredientCode, // Mapping IngredientCode to ItemCode
							UOM: item.UOM, // UOM stays the same
							Rate: item.Rate, // Rate stays the same
							PickRate: null,
							IngredientCode: item.IngredientCode, // Default value for PickRate if required
						}));

						this.setState({
							items: mappedSuggestions,
							filteredSuggestions: [],
							itemInputValue: "",
							selectedItemCode: 0,
							selectedItemValue: "",
						});
						//this.itemSearchRef.current.value = "";
						//this.itemSearchRef.current.focus();
						//this.setState({ filteredSuggestions: mappedSuggestions });
					} else {
						this.setState({ loading: false });
						this.props.alert.error(response.Message);
					}
				})
				.catch((err) => {
					console.log(err);
					this.props.alert.error(err.message);
					return;
				});
		} catch (error) {
			console.error("Fetch error:", error);
			throw error;
		}
	};

	//Ingredient Item Rate
	getIngredientItemRate = async (ingredientcode) => {
		try {
			let RequestIP = "127.0.0.0";
			var _orgid = sHelpers.secureStorage.getItem("inv.organization.id");
			const encodedCredentials = btoa(
				`${Config.invAPIUserName}:${Config.invAPIPassword}`
			);

			await fetch(
				Config.baseAPIUrl +
				"common/ingredientitemrate?organizationId=" +
				_orgid +
				"&requestip=" +
				RequestIP +
				"&ingredientcode=" +
				ingredientcode,
				{
					method: "GET",
					headers: {
						Authorization: "Basic " + encodedCredentials,
						"Content-type": "application/json",
					},
				}
			)
				.then((res) => res.json())
				.then((response) => {
					//	console.log("ingredientitemrate", response);
					if (response.IsSuccessful) {
						this.setState({ selectedItemRate: response.Rate });
					} else {
						this.setState({ loading: false });
						this.props.alert.error(response.Message);
					}
				})
				.catch((err) => {
					console.log(err);
					this.props.alert.error(err.message);
					return;
				});
		} catch (error) {
			console.error("Fetch error:", error);
			throw error;
		}
	};

	//save
	handleSaveModeClick = async (e) => {
		try {
			e.preventDefault();

			if (this.props.location.state.mode === "Edit") {
				this.updatekitchenclosingList(
					this.props.location.state.kitchenclosingno
				); // edit list
				//console.log(this.props.location.state.kitchenclosingno,'123');
			} else {
				this.handleSaveClick(); // New list
			}
		} catch (err) {
			console.log(err);
		}
	};

	// New mode save
	handleSaveClick = async () => {
		try {
			//	e.preventDefault();

			if (this.state.selectedItems.length === 0) {
				this.props.alert.error("Please add at least one item");
				document.getElementById("item-name").focus();
				this.handleScrollIntoView(this.itemSearchRef);
				return false;
			}

			if (this.state.kitchenList.length === 0) {
				this.props.alert.error("Please select the Kitchen");
				document.getElementById("kitchen-list").focus();
				this.handleScrollIntoView(this.itemSearchRef);
				return false;
			}

			this.setState({ btnDisabled: true });

			let postObj = {
				propertycode: parseInt(this.state.selectedPropertyCode), // Ensure it's an integer
				kitchencode: parseInt(this.state.selectedKitchenclosingCode), // Ensure it's an integer
				login: sHelpers.secureStorage.getItem("inv.user.name"), // User login from secure storage
				SelectedKitchenClosingItems: this.state.selectedItems, // Pass the array of items
				requestip: "0.0.0.1", // Static IP
			};

			//console.log("postObj:", JSON.stringify(postObj));

			const encodedCredentials = btoa(
				`${Config.invAPIUserName}:${Config.invAPIPassword}`
			);

			// Make the API request
			await fetch(Config.baseAPIUrl + "store/kitchenclosings", {
				method: "POST",
				headers: {
					Authorization: "Basic " + encodedCredentials,
					"Content-Type": "application/json",
				},
				body: JSON.stringify(postObj),
			})
				.then((res) => res.json())
				.then((response) => {
					if (response.IsSuccessful) {
						this.props.alert.success(
							response.Message +
							" Kitchenclosing No: " +
							response.KitchenClosingNo
						);
						setTimeout(() => {
							window.location.reload(true);
							this.setState({ selectedItems: [] });
						}, Config.averageMsgTimeout);
					} else {
						this.props.alert.error(response.Message || response[0]);
						this.setState({ btnDisabled: false });
					}
				})
				.catch((err) => {
					console.log(err);
					this.setState({ btnDisabled: false });
					this.props.alert.error(err.message);
				});
		} catch (error) {
			console.error("Fetch error:", error);
			throw error;
		}
	};

	// Edit mode save
	updatekitchenclosingList = async () => {
		try {
			//e.preventDefault();

			let postObj = {
				propertycode: parseInt(this.state.selectedPropertyCode), // Ensure it's an integer
				kitchencode: parseInt(this.state.selectedKitchenclosingCode), // Ensure it's an integer
				login: sHelpers.secureStorage.getItem("inv.user.name"), // User login from secure storage
				SelectedKitchenClosingItems: this.state.selectedItems, // Pass the array of items
				requestip: "0.0.0.1", // Static IP
			};

			//console.log("EditpostObj:", JSON.stringify(postObj));

			const encodedCredentials = btoa(
				`${Config.invAPIUserName}:${Config.invAPIPassword}`
			);

			// Make the API request
			await fetch(
				Config.baseAPIUrl +
				"store/kitchenclosings/" +
				this.props.location.state.kitchenclosingno +
				"/" +
				sHelpers.secureStorage.getItem("inv.user.name"),
				{
					method: "PUT",
					headers: {
						Authorization: "Basic " + encodedCredentials,
						"Content-Type": "application/json",
					},
					body: JSON.stringify(postObj),
				}
			)
				.then((res) => res.json())
				.then((response) => {
					//console.log("EditKitchen", response);
					if (response.IsSuccessful) {
						this.props.alert.success(
							response.Message +
							" Kitchenclosing No: " +
							response.KitchenClosingNo
						);
						setTimeout(() => {
							window.location.reload(true);
							this.setState({ selectedItems: [] });
						}, Config.averageMsgTimeout);
					} else {
						this.setState({ loading: false });
						this.props.alert.error(response.Message);
					}
				})
				.catch((err) => {
					console.log(err);
					this.props.alert.error(err.message);
					return;
				});
		} catch (error) {
			console.error("Fetch error:", error);
			throw error;
		}
	};

	// view Kitchen list
	getViewKitchenClosingList = async (kitchenclosingno) => {
		try {
			const encodedCredentials = btoa(
				`${Config.invAPIUserName}:${Config.invAPIPassword}`
			);

			await fetch(
				Config.baseAPIUrl + "store/kitchenclosings/" + kitchenclosingno,
				{
					method: "GET",
					headers: {
						Authorization: "Basic " + encodedCredentials,
						"Content-type": "application/json",
					},
				}
			)
				.then((res) => res.json())
				.then((response) => {
					//console.log("getViewKitchenClosingList", response);
					if (response.IsSuccessful) {
						this.setState({ selectedItems: response.Detail });

						this.getKitchenList(response.Header.PropertyCode);

						setTimeout(() => {
							this.setState({
								selectedPropertyCode: response.Header.PropertyCode,
								selectedPropertyName: response.Header.PropertyName,
								selectedKitchenclosingCode: response.Header.KitchenCode,
								selectedKitchenName: response.Header.KitchenName,
							});
						}, Config.minMsgTimeout);

						this.setState({
							kitchenclosingDate:
								response.Header.KitchenClosingDate +
								" " +
								response.Header.KitchenClosingTime,
							displayKitchenNo: response.Header.DisplayKitchenClosingNo,
							fields: { ...this.state.fields },
						});

						if (this.props.location.state.mode === "Edit") {
							this.handleChangeProperty({
								target: { value: response.Header.PropertyCode },
							});
						}
					} else {
						this.setState({ loading: false });
						this.props.alert.error(response.Message);
					}
				})
				.catch((err) => {
					console.log(err);
					this.props.alert.error(err.message);
					return;
				});
		} catch (error) {
			console.error("Fetch error:", error);
			throw error;
		}
	};

	// Delete Kitchen list
	deleteKitchenClosingList = async () => {
		try {
			const encodedCredentials = btoa(
				`${Config.invAPIUserName}:${Config.invAPIPassword}`
			);

			await fetch(
				Config.baseAPIUrl +
				"store/kitchenclosings/" +
				this.props.location.state.kitchenclosingno +
				"/" +
				sHelpers.secureStorage.getItem("inv.user.name"),
				{
					method: "DELETE",
					headers: {
						Authorization: "Basic " + encodedCredentials,
						"Content-type": "application/json",
					},
				}
			)
				.then((res) => res.json())
				.then((response) => {
					//console.log("deleteKitchenClosingList", response.Message);
					if (response.IsSuccessful) {
						this.props.alert.success(response.Message);
						setTimeout(() => {
							this.goBack();
						}, 2000);
					} else {
						this.setState({ loading: false });
						this.props.alert.error(response.Message);
						setTimeout(() => {
							this.goBack();
						}, 2000);
					}
				})
				.catch((err) => {
					console.log(err);
					this.props.alert.error(err.message);
					return;
				});
		} catch (error) {
			console.error("Fetch error:", error);
			throw error;
		}
	};

	render() {
		const totalAmount = this.state.selectedItems.reduce(
			(sum, item) => sum + parseFloat(item.KitchenClosingAmount),
			0
		);
		const totalItems = this.state.selectedItems.length;
		const { paddingTop, paddingBottom, mode } = this.state;

		return (
			<>
				<PageLoading />
				<main
					className="h-100"
					ref={this.mainRef}
					style={{
						paddingTop: `${paddingTop}px`,
						paddingBottom: `${paddingBottom}px`,
					}}
				>
					<header ref={this.headerRef} className="header position-fixed">
						<div className="row">
							<div className="col-auto">
								<button
									className="btn btn-light btn-44 back-btn"
									onClick={this.goBack.bind(this)}
								>
									<i className="bi bi-arrow-left"></i>
								</button>
							</div>
							<div className="col align-self-center text-center">
								<h5> Closings</h5>
							</div>
						</div>
					</header>

					<div className="main-container container">
						<div className="row mb-3">
							<div className="col">
								<h6 className="title">Kitchen Closing Entry</h6>
							</div>
							<div className="col-auto align-self-center">
								<span className="tag bg-primary text-white border-0 py-1 px-2 float-end mt-1">
									{mode}
								</span>
							</div>
						</div>
						<div className="row mb-2">
							<div className="col">
								<button
									className="btn btn-default shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white w-100"
									onClick={this.handleReload.bind(this)}
									disabled={this.state.btnDisabled}
									style={{
										display: this.state.resetdisabled ? "block" : "none",
									}}
								>
									<i className="bi bi-reply-fill me-2"></i>Reset
								</button>
							</div>
							<div className="col">
								<button
									className="btn btn-default shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white w-100"
									onClick={this.handleSaveModeClick.bind(this)}
									disabled={this.state.btnDisabled}
									style={{ display: this.state.btnVisible ? "block" : "none" }}
								>
									<i className="bi bi-check-lg me-2"></i> Save
								</button>
							</div>
							{this.state.btnDeleteVisabled && (
								<div className="col">
									<button
										className="btn btn-danger shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white w-100"
										onClick={this.deleteKitchenClosingList.bind(this)}
									>
										<i className="bi bi-trash me-2"></i> Delete
									</button>
								</div>
							)}
						</div>
						<div className="row mb-4">
							<div className="col-auto">
								<div className="avatar avatar-44 rounded-10 shadow-sm">
									<i className="bi bi-calendar-range size-22"></i>
								</div>
							</div>

							<div className="col align-self-center ps-0">
								<h6>{this.state.kitchenclosingDate}</h6>
								<p className="mb-1 text-muted">Date</p>
							</div>

							{this.state.displayKitchenNo > 0 && (
								<div className="col align-self-center text-center text-end">
									<h6>{this.state.displayKitchenNo}</h6>
									<p className="mb-1 text-muted">Closing No</p>
								</div>
							)}
						</div>
						<div className="row h-100">
							<div className="col-12 col-md-6 col-lg-4">
								<div className="form-floating mb-3">
									<select
										className="form-control"
										id="kitchen-properties"
										onChange={this.handleChangeProperty.bind(this)}
										value={this.state.selectedPropertyCode}
										disabled={this.state.editdisabled} // Only disabled in edit mode
									>
										{this.state.propertyList.map((prop) => (
											<option value={prop.PropertyCode} key={prop.PropertyCode}>
												{prop.PropertyName}
											</option>
										))}
									</select>
									<label htmlFor="country">Property</label>
								</div>
							</div>

							<div className="col-12 col-md-6 col-lg-4">
								<div className="form-floating mb-3">
									<select
										className="form-control"
										id="kitchen-list"
										onChange={this.handleChangeKitchenList}
										value={this.state.selectedKitchenclosingCode}
										disabled={
											this.state.editdisabled ||
											this.state.kitchenList.length === 0
										}
									>
										{this.state.kitchenList &&
											this.state.kitchenList.length > 0 &&
											this.state.kitchenList.map((kitchen) => (
												<option
													value={kitchen.KitchenCode} // Use KitchenCode for the value
													key={kitchen.KitchenCode}
												>
													{kitchen.KitchenName} {/* Display KitchenName */}
												</option>
											))}
									</select>
									<label htmlFor="kitchen-list">Kitchen List</label>
								</div>
							</div>
							{!(
								this.props.location.state.mode === "View" ||
								this.props.location.state.mode === "Delete"
							) && (
									<>
										<div className="col-12 col-md-6 col-lg-4">
											<div className="form-floating mb-3">
												<select
													className="form-control"
													id="type-list"
													onChange={this.handleTypeChange} // Handle selection change
													disabled={this.state.ctrlDisabled}
													value={this.state.selectedTypeId}
												>
													{this.state.typeLists.map((type) => (
														<option value={type.id} key={type.id}>
															{type.name}
														</option>
													))}
												</select>
												<label htmlFor="type-list">Type</label>
											</div>
										</div>

										{this.state.showStoreList && ( // Conditionally render this block if showStoreList is true
											<div className="col-12 col-md-6 col-lg-4">
												<div className="form-floating mb-3 ">
													<select
														className="form-control"
														id="variation-stores"
														onChange={this.handleStoreChange.bind(this)}
														disabled={this.state.ctrlDisabled}
													>
														{this.state.storeList.map((store) => (
															<option
																value={store.StoreCode}
																key={store.StoreCode}
															>
																{store.StoreName}
															</option>
														))}
													</select>
													<label htmlFor="variation-stores">Store</label>
												</div>
											</div>
										)}
									</>
								)}
						</div>
						<div className="row">
							{!(
								this.props.location.state.mode === "View" ||
								this.props.location.state.mode === "Delete"
							) && (
									<>
										<div className="col-7">
											<div className="form-group form-floating mb-2 shadow-sm">
												<div className="form-group form-floating">
													<input
														ref={this.itemSearchRef}
														className="autocomplete-input form-control"
														type="text"
														value={this.state.itemInputValue || ""}
														onChange={this.handleItemChange}
														onFocus={this.handleItemFocus}
														id="item-name"
														autoComplete="off"
														disabled={this.state.ctrlDisabled}
													/>

													<label htmlFor="item-name">Item Search</label>
												</div>

												<ul className="autocomplete-suggestions">
													{this.state.filteredSuggestions.map(
														(suggestion, index) => (
															<li
																key={index}
																className="autocomplete-suggestion"
																onClick={() =>
																	this.handleItemSelect(
																		suggestion.ItemName,
																		suggestion.ItemCode,
																		suggestion.UOM,
																		suggestion.Rate,
																		suggestion.PickRate,
																		suggestion.RecipeItemcode,
																		suggestion.IngredientCode
																	)
																}
															>
																{suggestion.ItemName}
															</li>
														)
													)}
												</ul>
											</div>
										</div>

										<div className="col-5">
											<input
												ref={this.qtyRef}
												type="text"
												className="trasparent-input text-end variation-entry-qty"
												id="kitchen-qty"
												placeholder="0.00"
												onChange={this.handleChange.bind(this, "kitchenqty")}
												value={this.state.fields["kitchenqty"]}
												maxLength="10"
												autoComplete="off"
												disabled={this.state.ctrlDisabled}
												inputmode="numeric"
											/>
										</div>

										<div className="col-12 mb-2">
											<span className="text-muted size-12 me-2">
												Units:{" "}
												<span className="text-color-theme size-12 small">
													{this.state.selectedItemUnits}
												</span>{" "}

											</span>
											<span className="text-muted size-12 me-2">
												Rate:{" "}
												<span className="text-color-theme size-12 small">
													{this.state.selectedItemRate}
												</span>{" "}
												{this.state.showEditRateIcon && (
													<i
														className="bi bi-pencil"
														onClick={this.handleAddItemRatePopup.bind(this)}
													></i>
												)}
												{/* <i className="bi bi-pencil" onClick={this.handleAddItemRatePopup.bind(this)}></i> */}
											</span>
										</div>
									</>
								)}

							<div className="col-12">
								{/* <button className="btn btn-default shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white w-100" onClick={this.handleAddItemRatePopup.bind(this)}> */}
								<button
									className="btn btn-default shadow-sm avatar avatar-44 p-0 mb-3 me-2 text-white w-100"
									onClick={this.handleAddItemClick.bind(this)}
									disabled={this.state.btnDisabled}
									style={{ display: this.state.btnVisible ? "block" : "none" }}
								>
									<i className="bi bi-plus-lg me-2"></i> Add Item
								</button>

								{/* Display Total Amount and Total Items */}
								<div className="row my-3">
									{totalAmount > 0 && (
										<div className="col-6 text-start">
											<span className="text-muted size-12 me-2">
												Total Amount:{" "}
												<span className="text-color-theme size-12 small">
													{totalAmount.toFixed(2)}
												</span>
											</span>
										</div>
									)}
									{totalItems > 0 && (
										<div className="col-6 text-end">
											<span className="text-muted size-12 me-2">
												Total Items:{" "}
												<span className="text-color-theme size-12 small">
													{totalItems}
												</span>
											</span>
										</div>
									)}
								</div>
							</div>
						</div>
						<div className="row mb-4">
							<div className="card shadow-sm mb-4">
								<div className="col-12 px-0">
									<ul className="list-group list-group-flush bg-none">
										{this.state.selectedItems.map((item, id) => (
											<li className="list-group-item" id={"itm-sl-" + id}>
												<div className="row">
													<div className="col-auto align-self-center text-end">
														<p className="text-color-theme mb-0">
															{item.ItemName}
														</p>
														<p className="size-12">{item.TypeName}</p>
														<p className="text-muted size-12">
															{item.StoreName}
														</p>
													</div>
													<div className="col align-self-center text-end">
														<p className="mb-0">{item.units}</p>
														<p className="text-muted size-12">
															Rate: {item.KitchenClosingRate}
														</p>
													</div>
													<div className="col align-self-center text-end">
														{/* <p className="mb-0">{item.KitchenClosingQuantity}</p> */}
														<p className="text-muted size-12">
															Qty: {item.KitchenClosingQuantity}
														</p>
														<p className="text-muted size-12">
															Amount: {item.KitchenClosingAmount}
														</p>
													</div>

													<div className="col-auto align-self-center">
														<button
															className="btn btn-light text-danger shadow-sm"
															onClick={this.handleDeleteItemCick.bind(
																this,
																item.ItemCode,
																item.storeCode
															)}
															style={{
																display: this.state.btnVisible
																	? "block"
																	: "none",
															}}
														>
															<i className="bi bi-trash"></i>
														</button>
													</div>
												</div>
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>
					</div>
				</main>
				<AddItemRatePopup
					ref={addItemRef}
					onRateUpdate={this.handleRateUpdate}
				//itemName={this.state.itemInputValue}
				/>
			</>
		);
	}
}
export default withAlert()(kitchenclosingentry);
